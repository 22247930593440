.comment-list {
    max-width: 800px;
    margin: 0;
}

/* 评论项样式 */
.comment-item {
    padding: 0.75rem;
    margin: 0.75rem 0;
    border-radius: 4px;
    background: var(--color-block, #f8f9fa);
    border: none;
}

/* 评论项样式 */
.comment-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.comment-content {
    line-height: 1.38;
    margin: 0.5rem 0;
    color: var(--color-text, #333);
}

.comment-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
}

/* 按钮与图片样式 */
.like-btn {
    cursor: pointer;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    background: transparent; /* 改为透明背景 */
    border: none;
    transition: all 0.2s ease;
    color: var(--color-meta, #666); /* 添加默认颜色 */
}

.like-btn:hover, 
.like-btn.active:hover {
    background: rgba(0, 0, 0, 0.05); /* 轻微的hover背景 */
    color: #ff4444; /* 鼠标悬停时显示红色 */
}

.like-btn.active {
    background: transparent; /* 激活状态也保持透明背景 */
}

.like-btn.active i,
.like-btn.active {
    color: #ff4444 !important; /* 强制激活状态显示红色 */
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    justify-content: flex-start;
    margin: 0;
}

.gallery img {
    max-width: 100%;
    border-radius: 4px;
    max-height: 60vh;
    width: auto;
    object-fit: contain;
}

/* 用户信息样式 */
.user-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-meta {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* 辅助信息样式 */
.tag {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    font-size: 0.875rem;
    color: var(--color-meta, #666);
}

.tag i {
    margin-right: 4px;
}

.comment-time {
    font-size: 0.875rem;
    color: var(--color-meta, #666);
}

.user-device {
    display: flex;
    gap: 8px;
    font-size: 0.75rem;
    color: #666;
}

.device-info-bottom {
    display: flex;
    gap: 12px;
    font-size: 0.75rem;
    color: var(--color-meta, #666);
}

.device-info {
    display: flex;
    align-items: center;
    gap: 4px;
}

.device-info i {
    font-size: 0.875rem;
}

/* 用户标签样式 */
.user-name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.user-name strong {
    margin-right: 8px;
    color: var(--color-text, #333);
}

.user-label {
    display: inline-flex;
    font-size: 0.75rem;
    padding: 0.1rem 0.5rem;
    border-radius: 3px;
    background: var(--color-theme, #44D7B6);  /* 修改为主题色 */
    color: #fff;  /* 修改为白色文字 */
    margin-right: 4px;
    opacity: 0.8;  /* 添加透明度使其看起来柔和一些 */
}

.user-label.admin {
    background: #00BCD4;  /* 修改管理员标签颜色为浅蓝色 */
    color: white;
    opacity: 1;  /* 管理员标签保持完全不透明 */
}

/* 评论计数和控制面板样式 */
.walinet-comment-count {
    color: var(--color-text);
    font-size: 0.875rem;
}

.control-panel {
    max-width: 800px;
    margin: 0.75rem auto; /* 减小上下margin */
    padding: 0.75rem; /* 减小内部padding */
    background: var(--color-block, #f8f9fa);
    border-radius: 4px;
    border: none;
    box-sizing: border-box;
    display: none;
}

.control-panel.visible {
    display: block;
}

.toggle-group {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
}

.toggle-btn {
    flex: 1;
    height: 36px;
    border: none;
    border-radius: 4px;
    background: var(--color-block, #f8f9fa);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px; /* 添加图标和文字间距 */
    white-space: nowrap;
    padding: 0 1rem;
    min-width: 0;
    color: var(--color-text, #333);
}

.toggle-btn.active {
    background: var(--color-theme, #44D7B6);
    color: #fff; /* 激活状态文字改为白色 */
    border-color: var(--color-theme, #44D7B6);
    opacity: 0.8;
}

.toggle-btn.active i {
    color: #fff; /* 激活状态图标改为白色 */
}

.toggle-btn:hover {
    filter: brightness(0.95);
    opacity: 1; /* 悬停时保持完全不透明 */
}

/* 动态布局样式 */
#commentList > section > div > div > div {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 16px;
    margin-top: 8px;
}

#commentList > section > div > div { 
    border-radius: 4px;
    background: var(--color-block, #fff);
}

[color-scheme='dark'] #commentList > section > div > div {
    background: var(--color-card, #222);
}

/* 加载更多按钮样式 */
.load-more {
    margin: 0.75rem 0; /* 减小上下margin */
}

.load-more-btn {
    width: 100%;
    padding: 0.75rem; /* 减小按钮padding */
    background: var(--color-block, #f8f9fa);
    border: none;
    border-radius: 4px;
    color: var(--color-meta, #666);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    text-align: center;
}

.load-more-btn:hover {
    filter: brightness(0.95);
}

/* CSS变量定义 */
:root {
    --color-text: #444;
    --color-meta: #888;
    --color-theme: #44D7B6;
    --color-theme-dark: #00BCD4;
    --color-border: #ecf0f1;
    --color-block: #f6f6f6;
    --color-card: #fff;
}

/* 暗色模式变量 */
[color-scheme='dark'] {
    --color-text: #eeeeeede;
    --color-meta: #bfbfbfde;
    --color-theme: #44D7B6;
    --color-theme-dark: #00BCD4;
    --color-border: #434343;
    --color-block: #434343;
    --color-card: #262626;
}

/* 移除所有的边框和阴影 */
.comment-item,
.control-panel,
.load-more-btn,
.toggle-btn {
    box-shadow: none;
}

/* 响应式布局 */
@container talk-container (max-width: 300px) {
    /* 用户信息布局调整 */
    .user-name {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    /* 设备信息布局调整 */
    .device-info-bottom {
        flex-direction: column;
        gap: 4px;
    }

    /* 确保底部布局正确 */
    .comment-bottom {
        align-items: flex-start;
    }

    /* 增大点赞按钮的可点击区域 */
    .like-btn {
        padding: 0.5rem 0.75rem;
    }

    /* 用户名和标签的布局 */
    .user-name {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .user-name > strong {
        margin-bottom: 4px;
    }

    .user-label-container {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;
    }

    /* 用户标签布局 */
    .user-label {
        display: block;
        margin: 4px 0;
    }

    /* 底部信息布局 */
    .comment-bottom {
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        padding-top: 8px;
    }

    /* 设备信息在底部的布局 */
    .device-info-bottom {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-right: auto;
    }

    /* 点赞按钮靠右显示 */
    .like-btn {
        margin-left: auto;
        padding: 0.5rem 0.75rem;
    }

    /* 确保标签和设备信息的文字大小一致 */
    .device-info, .user-label {
        font-size: 0.75rem;
    }

    /* 添加更清晰的用户信息上下间距 */
    .user-meta > * {
        margin-bottom: 4px;
    }

    .user-meta > *:last-child {
        margin-bottom: 0;
    }

    .time-location {
        max-width: 100%;  /* 在窄屏下允许占用全宽 */
        align-items: flex-start;
    }

    /* 控制面板布局调整 */
    .toggle-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 强制两列布局 */
        gap: 8px;
    }

    .toggle-btn {
        width: 100%; /* 使按钮填满格子 */
        min-width: 0; /* 移除最小宽度限制 */
    }
}

/* 添加容器查询支持 */
#talkContainer {
    container-type: inline-size;
    container-name: talk-container;
}

/* 时间和地点样式 */
.time-location {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    max-width: 40%;  /* 限制最大宽度 */
}

.comment-location {
    font-size: 0.875rem;
    color: var(--color-meta, #666);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;  /* 使用最大可用空间 */
}

.comment-location i {
    margin-right: 4px;
}

.comment-time {
    font-size: 0.875rem;
    color: var(--color-meta, #666);
}